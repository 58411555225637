var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "lobby-screen",
      attrs: {
        dark: "",
        absolute: "",
        value: !!(_vm.$slots.default || _vm.$scopedSlots.default),
        stateless: "",
        right: "",
        "hide-overlay": "",
        width: _vm.width,
      },
    },
    [
      _c("img", {
        staticClass: "lobby-screen__top-image",
        attrs: { src: _vm.topImageURL },
      }),
      _c("img", {
        staticClass: "lobby-screen__bottom-image",
        attrs: { src: _vm.bottomImageURL },
      }),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "", "mb-2": "" } },
            [
              !!_vm.$slots.close ? _vm._t("close") : _vm._e(),
              _c(
                "v-layout",
                { attrs: { row: "", "align-end": "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-layout",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "RtbControlButton",
                            {
                              staticClass: "ml-auto",
                              attrs: { variant: "outline" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "lobby-screen__container", attrs: { "d-flex": "" } },
            [
              !!(_vm.title || _vm.description)
                ? _c(
                    "v-layout",
                    { attrs: { "mb-4": "", shrink: "", "align-center": "" } },
                    [
                      !!_vm.title
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "lobby-screen__title",
                              attrs: { shrink: "" },
                            },
                            [_vm._v(" " + _vm._s(_vm.title) + " ")]
                          )
                        : _vm._e(),
                      !!_vm.description
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "lobby-screen__description",
                              attrs: { "ml-4": "", shrink: "" },
                            },
                            [_vm._v(" " + _vm._s(_vm.description) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                {
                  staticStyle: { position: "relative" },
                  attrs: { shrink: "" },
                },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }