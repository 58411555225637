<template>
  <v-navigation-drawer
    dark
    absolute
    :value="!!($slots.default || $scopedSlots.default)"
    stateless
    right
    hide-overlay
    :width="width"
    class="lobby-screen"
  >
    <img :src="topImageURL" class="lobby-screen__top-image" />
    <img :src="bottomImageURL" class="lobby-screen__bottom-image" />
    <v-layout column>
      <v-flex shrink mb-2>
        <slot name="close" v-if="!!$slots.close" />
        <v-layout row align-end>
          <v-spacer></v-spacer>
          <v-layout shrink>
            <v-flex>
              <RtbControlButton
                @click="$emit('close')"
                class="ml-auto"
                variant="outline"
              >
                Close
              </RtbControlButton>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <div d-flex class="lobby-screen__container">
        <v-layout mb-4 shrink align-center v-if="!!(title || description)">
          <v-flex shrink v-if="!!title" class="lobby-screen__title">
            {{ title }}
          </v-flex>
          <v-flex
            ml-4
            shrink
            v-if="!!description"
            class="lobby-screen__description"
          >
            {{ description }}
          </v-flex>
        </v-layout>

        <v-flex shrink style="position: relative">
          <slot />
        </v-flex>
      </div>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import Vue from "vue"
import { RtbControlButton } from "@/components/ui"

const LOBBY_PAGE_CONTENT_MAX_WIDTH = 1600
const RIGHT_TOOLBAR_WIDTH = 60
const PEOPLE_COLUMN_WIDTH = 400

const DEFAULT_TOP_IMAGE = require("../../assets/v-drawer/v-drawer-ractangle.png")
const DEFAULT_BOTTOM_IMAGE = require("../../assets/v-drawer/v-drawer-circles.png")

export default Vue.extend({
  name: "LobbyScreen",
  components: { RtbControlButton },
  props: {
    title: String,
    description: String,
    visibility: Boolean,
    topImageURL: {
      type: String,
      default: DEFAULT_TOP_IMAGE
    },
    bottomImageURL: {
      type: String,
      default: DEFAULT_BOTTOM_IMAGE
    }
  },
  data() {
    return {
      contentWidth: 0
    }
  },
  computed: {
    width() {
      const contentWdthMoreThanMaxWidth =
        this.contentWidth > LOBBY_PAGE_CONTENT_MAX_WIDTH

      return contentWdthMoreThanMaxWidth
        ? this.contentWidth +
            RIGHT_TOOLBAR_WIDTH / 2 -
            (PEOPLE_COLUMN_WIDTH +
              (this.contentWidth - LOBBY_PAGE_CONTENT_MAX_WIDTH) / 2)
        : this.contentWidth - PEOPLE_COLUMN_WIDTH
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize)
    this.onWindowResize()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.contentWidth = window.innerWidth
    }
  }
})
</script>
<style lang="scss">
.lobby-screen {
  $button-size: 192px;
  background-color: #586475 !important;
  padding: 10px;
  display: flex;
  z-index: 102;

  &__container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  &__bottom-image {
    display: block;
    position: absolute;
    width: 273px;
    background-repeat: no-repeat;
    background-size: cover;
    left: 2%;
    bottom: 15%;
  }

  &__top-image {
    display: block;
    position: absolute;
    width: 168px;
    background-repeat: no-repeat;
    background-size: cover;
    top: 10%;
    right: 12%;
    z-index: -1;
  }

  &__title {
    font-size: 32px;
    font-family: "Right Grotesk", san-serif;
    font-weight: 900;
    line-height: 38px;
    text-transform: uppercase;
  }
}
</style>
